@import "../all/_variables";

body {
  background: #FFF;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $opensans;

  strong {
    font-weight: 900;
  }
}

.half-padding {
  padding-left: 7px;
  padding-right: 7px;
}

.row.xlarge {
  max-width: 90.625em;
}


.left {
  float: left;
}

.right {
  float: right;
}

[placeholder]:focus::-webkit-input-placeholder {
  color: transparent;
}

i.fa-videos:before {
  content: "\f03d";
}

i.fa-articles:before {
  content: "\f4a4";
}

i.fa-podcasts:before {
  content: "\f2ce";
}

i.fa-drumming-styles:before {
  font-family: 'drumeo' !important;
  content: "\59";
}

i.fa-health-center:before {
  content: "\f21e";
}

i.fa-rudiments:before {
  font-family: 'drumeo' !important;
  content: "\75";
}


.beat-navigation {
  background: #000c17;
  text-align: center;
  border: 1px solid #111c26;
  border-width: 1px 0;
  font-size: 0;
  position: sticky;
  top: 0;
  transition: top .4s;
  z-index: 100;

  &.scrollUp {
    top: 40px;
    @include tablet {
      top: 56px;
    }
  }

  .nav-item {
    text-align: center;
    color: #FFF;
    position: relative;
    width: auto;
    display: inline-block;
    vertical-align: middle;
    font: 700 0/1em $opensans;
    transition: all .3s;
    padding: 12px 13px;
    max-height: 40px;
    opacity: 0.7;
    @include tablet {
      font-size: 11px;
      padding: 13px 14px;
      max-height: 52px;
    }
    @include desktop {
      padding: 13px 30px;

    }

    &:hover {
      @include tablet {
        background: lighten(#000c17, 5%);
        opacity: 0.8;
      }
    }

    &.active,
    &.active:hover {
      opacity: 1;
    }

    img {
      height: 16px;
      @include tablet {
        height: 29px;
      }
    }

    i {
      color: $drumeoBlue;
      font-size: 16px;
      line-height: 16px;
      display: block;
      @include tablet {
        font-size: 15px;
        line-height: 15px;
        margin: 0 auto 3px;
      }
    }

  }
}

.feat-post-wrap form#searchform,
.single-search form#searchform {
  padding: 10px 15px;
  opacity: 0.7;
  font-size: 0;
  transition: opacity .4s;

  &:focus-within {
    opacity: 1;

    input {
      width: 150px;
      font-size: 14px;
    }

    button {
      color: $drumeoBlue;
      font-size: 20px;
    }
  }

  input {
    background: transparent;
    border: none;
    margin: 0 10px 0 0;
    width: 100px;
    display: inline-block;
    padding: 3px 0;
    font: 400 12px/1em "Open Sans";
    height: auto;
    vertical-align: middle;
    transition: all .4s;
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }
  }

  button {
    font-size: 18px;
    vertical-align: middle;
    line-height: 1em;
    outline: none;
    transition: all .4s;
  }
}

.single-search form#searchform {
  text-align: right;

  input {
    border-bottom: 1px solid #000;
    color: #000;

    &::placeholder {
      color: #000 !important;
    }
  }

  button {
    color: #000;
  }
}

.feat-post-wrap {
  background: #000c17;
  text-align: center;
  position: relative;
  z-index: 1;

  form#searchform {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;

    input {
      border-bottom: 1px solid #fff;
      color: #fff;

      &::placeholder {
        color: #fff !important;
      }
    }

    button {
      color: #fff;
    }
  }

  .featured-posts {
    background-color: #000c17;
    background-position: 50% 0;
    background-size: cover;
    text-align: center;
    margin: 0 auto;
    max-width: 1200px;
    position: relative;
    z-index: 1;
    float: none;
    height: 330px;
    @include tablet {
      height: 500px;
    }
    @include desktop {
      height: 560px;
    }

    &:before {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      background: linear-gradient(0deg, #000c17 20%, transparent 55%);
      @include tablet {
        background: linear-gradient(0deg, #000c17 15%, transparent 50%);
      }
    }

    &:after {
      @media (min-width: 1200px) {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(90deg, #000c17 0%, transparent 10%, transparent 90%, #000c17 100%);
        z-index: 0;
      }
    }

    i.center-icon {
      color: #fff;
      position: absolute;
      z-index: 1;
      text-shadow: 0 0 15px #000;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all .3s;
      font-size: 45px;
      @include tablet {
        font-size: 65px;
      }

      &.play-video {
        outline: none;
        cursor: pointer;
        transition: all .3s;

        &:hover {
          text-shadow: 0 0 20px #000;
          font-size: 46px;
          @include tablet {
            font-size: 66px;
          }
        }
      }
    }

    .row {
      width: 100%;
      bottom: 30px;
      left: 50%;
      position: absolute;
      z-index: 2;
      transform: translate(-50%, 0);
      padding: 0 7px;
      @include tablet {
        bottom: 40px;
        max-width: 570px;
      }
      @include desktop {
        max-width: 880px;

      }

      h2 {
        font: 700 13px/1.3em $opensans;
        text-transform: uppercase;
        color: #8c9697;
        margin: 0 auto;
        text-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
      }

      h1 {
        color: #FFF;
        font: 900 28px/1.2em $opensans;
        margin: 0 auto;
        text-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
        @include tablet {
          font-size: 38px;
        }
        @include desktop {
          font-size: 48px;
        }
      }
    }

    &.with-link {
      display: inline-block;

      a:hover {
        i {
          font-size: 68px;
        }
      }
    }

    &.podcast {
      box-shadow: none;
      border-bottom: none;
      padding: 80px 0 15px;
      @include tablet {
        padding: 210px 0 25px;
      }
      @include desktop {
        padding: 300px 0 45px;
      }

      .row {
        max-width: 75rem;
        @include tablet {
          padding: 0 20px;
        }

        .logo-wrap {
          display: inline-block;
          width: 100%;
          max-width: 290px;
          @include tablet {
            max-width: 550px;
          }
          @include desktop {
            max-width: 720px;
          }

          .logo {
            position: relative;
            @include desktop {
              padding: 0 30px;
            }

            img {
              width: auto;
              max-height: 70px;
              margin: 0 auto 15px;
              @include tablet {
                max-height: 131px;
                margin: 0 auto 20px;
              }
              @include desktop {
                max-height: 165px;
              }
            }


            .join.outline {
              background: transparent;
              border: 2px solid #FFF;
              font-size: 15px;
              color: #FFF;
              width: 100%;
              user-select: none;
              padding: 7px;
              max-width: 120px;
              @include tablet {
                font-size: 18px;
                max-width: 180px;
              }
              @include desktop {
                font-size: 20px;
                max-width: 230px;
              }

              &:hover, &.active {
                background: #FFF;
                color: #000;
              }

              i {
                transition: transform .3s;
              }
            }

            .sub-list {
              background: #fff;
              border-radius: 5px;
              position: absolute;
              z-index: 2;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
              top: 105%;
              left: 50%;
              transform: translate(-50%, 0);
              max-height: 0;
              visibility: hidden;
              opacity: 0;
              overflow: hidden;
              transition: max-height 0.7s;
              padding: 10px 15px 3px;
              @include tablet {
                padding: 15px 20px 8px;
              }

              &.active {
                max-height: 500px;
                visibility: visible;
                opacity: 1;
              }

              a {
                width: auto;
                display: block;
                color: #000;
                font: 700 13px/1.2em $robotocondensed;
                text-transform: uppercase;
                margin: 0 auto 7px;
                white-space: nowrap;
                @include tablet {
                  font-size: 16px;
                }

                &:hover {
                  color: $drumeoBlue;
                }
              }
            }
          }
        }
      }
    }
  }

  &.simple .featured-posts {
    background: #000c17;
    box-shadow: none;
    height: 320px;

    .row {
      bottom: auto;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    i {
      position: unset;
      text-shadow: none;
      color: $drumeoBlue;
      font-size: 55px;
      display: inline;
    }

    h1 {
      text-transform: uppercase;
      font: 900 20px/1em $robotocondensed;
      text-shadow: none;
      margin: 10px auto;
      @include tablet {
        font-size: 60px;
      }
      @include desktop {
        font-size: 80px;
      }
    }

    h2 {
      text-shadow: none;
    }
  }
}

.content-grid {

  #filters {
    top: -75px;
    @include tablet {
      top: -100px;
    }
    @include desktop {
      top: -125px;
    }
  }

  .filtering {
    margin: 0 auto;
    text-align: center;
    font-size: 0;
    padding: 20px 20px 0;
    @include tablet {
      padding: 30px 34px 0;
    }
    @include desktop {
      padding: 55px 34px 0;
    }

    .row {
      max-width: 1136px;
    }

    &.two-wide h1 {
      width: 50%;
    }

    &.three-wide h1 {
      width: 33.33%;
    }

    &.four-wide h1 {
      width: 50%;
      @include tablet {
        width: 30%;
      }

      &.all {
        @include tablet {
          width: 10%;
        }
      }
    }

    &.five-wide h1 {
      width: 33.33%;
      @include tablet {
        width: 22%;
      }

      &.all {
        @include tablet {
          width: 12%;
        }
      }
    }

    &.six-wide h1 {
      width: 33.33%;
      @include desktop {
        width: 18%;
      }

      &.all {
        @include desktop {
          width: 10%;
        }
      }
    }

    &.seven-wide h1 {
      width: 33.33%;
      @include tablet {
        width: 25%;
      }
      @include desktop {
        width: 15.8%;
      }

      &.all {
        @include desktop {
          width: 5%;
        }
      }
    }

    &.eight-wide h1,
    &.eight-wide h1.all {
      width: auto;
      padding: 15px 13.9px;
      @include sm {
        padding: 15px 10.7px;
      }
      @include desktop {
        padding: 15px 26.7px;
      }
    }

    h1 {
      font: 700 12px/1.2em $robotocondensed;
      text-transform: uppercase;
      margin: 0 auto;
      color: #000;
      border-bottom: 5px solid #e9e9e9;
      padding: 15px 0;
      text-align: center;
      position: relative;
      transition: all .3s;
      display: inline-block;
      @include tablet {
        font-size: 15px;
      }

      &:hover {
        border-bottom: 5px solid transparentize($drumeoBlue, 0.9);
      }

      &.active, &.active:hover {
        border-bottom: 5px solid $drumeoBlue;
        background: #eef3fc;
      }

      a {
        color: inherit;
      }

      em {
        position: absolute;
        font-weight: 500;
        font-size: 11px;
        font-family: $opensans;
        transform: translate(-50%, 0);
        width: 100%;
        bottom: 0;
        text-transform: none;
      }
    }

    .filter {
      padding: 15px;
      
      @include tablet {
        padding:15px 24px;
      }

      @include desktop {
        padding:15px 28px;
      }
    }
  }

  .sub-filtering {
    margin-top: 30px;
    margin-bottom: 20px;
    @include tablet {
      margin-bottom: 40px;
    }

    .row {
      padding: 0 20px;
      text-align: center;
      
      @media (min-width: 461px) {
        text-align: left;
      }

      @include tablet {
        padding: 0 36px;
      }
    }

    h1 {
      font: 700 12px/1.2em $robotocondensed;
      text-transform: uppercase;
      margin-right: 10px;
      color: #575757;
      padding: 5px 0;
      text-align: center;
      position: relative;
      transition: all .3s;
      display: inline-block;
      border: 2px solid #818181;
      border-radius: 30px;
      min-width: 100px;
      margin-right: 5px;
      margin-bottom: 6px;

      @include tablet {
        font-size: 15px;
        min-width: 150px;
        padding: 10px 0;
        margin-right: 4px;
      }

      @include desktop {
        min-width: 170px;
        margin-right: 10px;
        margin-bottom: 0px;
      }

      &.active {
        background: #818181 !important;
        color: white !important;
      }
    }
  }

  .category-container {
    .row {
      padding: 0 20px;

      @include tablet {
        padding: 0 40px;
      }

      div {
        margin-top: 10px;
        border-bottom: 3px solid #0B75D9;

        h5 {
          color: #0B74D8;
          font-weight: 900;
          text-transform: uppercase;
        }
      }
    }
  }

  .white-box {
    position: relative;
    padding: 25px 10px;
    @include tablet {
      padding: 40px 20px;
    }
    @include desktop {
      padding: 50px 20px;
    }

    .post-ordering {
      position: relative;
      text-align: right;
      padding: 0 12px;
      margin: 0 auto 20px;

      .sub-toggle {
        font: 700 17px/1em 'Open Sans', sans-serif;
        color: $drumeoBlue;
        display: inline-block;
        cursor: pointer;
        transition: opacity .3s;

        &.active, &.active:hover, &:hover {
          opacity: 0.6;
        }
      }

      .sub-list {
        background: #fff;
        border-radius: 5px;
        position: absolute;
        z-index: 2;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        top: 110%;
        right: 10px;
        max-height: 0;
        visibility: hidden;
        opacity: 0;
        overflow: hidden;
        transition: max-height 0.7s;
        padding: 10px 15px 3px;
        @include tablet {
          padding: 15px 20px 8px;
          right: 12px;
        }

        &.active {
          max-height: 500px;
          visibility: visible;
          opacity: 1;
        }

        a {
          width: auto;
          display: block;
          color: #000;
          font: 700 13px/1.2em $robotocondensed;
          text-transform: uppercase;
          margin: 0 auto 7px;
          white-space: nowrap;
          @include tablet {
            font-size: 16px;
          }

          &:hover {
            color: $drumeoBlue;
          }
        }
      }
    }

    .section-header {
      padding: 30px 12px 0;
      text-transform: uppercase;
      font: 600 13px/1em $opensans;
      color: #000;

      a {
        color: inherit;
      }

      i {
        font-size: 20px;
        vertical-align: sub;
        margin-right: 5px;
        text-transform: none;
      }

      span {
        font-weight: 400;
        line-height: 18px;
      }

      hr {
        margin: 20px auto 25px;
        max-width: 100%;
      }
    }

    .post-grid {
      h1 {
        font: 500 18px/1.2em $opensans;
        margin: 0;
        width: auto;
        padding-bottom: 5px;
        @include tablet {
          font-size: 24px;
        }
        @include desktop {
          font-size: 32px;
        }
      }

      h3 {
        margin: 0 auto 15px;
      }

      &.homepage-feature {
        .post-tile {
          @include tablet {
            margin-bottom: 0;
          }

          &:nth-child(2) {
            margin-bottom: 0;
          }

          &:last-child {
            display: none;
            @include desktop {
              display: block;
            }
          }
        }
      }

      .post-tile {
        margin: 0 auto 20px;
        @include tablet {
          margin: 0 auto 25px;
          padding: 0 12px;
        }
        @include desktop {
          margin: 0 auto 30px;
        }

        &.DrumeoPodcast .image-fill:before,
        &.DrumeoGab .image-fill:before {
          content: '';
          position: absolute;
          bottom: 0;
          right: 0;
          width: 65px;
          height: 50px;
          background: rgba(0, 0, 0, 0.5) url(https://dpwjbsxqtam5n.cloudfront.net/podcast/logo.png) center center/49px no-repeat;
          border-radius: 5px 0 5px 0;
          z-index: 2;
        }

        &.DrumeoGab .image-fill:before {
          background-image: url(https://dpwjbsxqtam5n.cloudfront.net/beat/drumeo-gab-logo.svg);
        }

        a {
          color: #000;

          &:hover .image-fill {
            .thumbnail-pic {
              transform: scale(1.03);
            }

            i {
              opacity: 1;
            }
          }
        }

        .image-fill {
          position: relative;
          margin: 0 auto 17px;
          border-radius: 5px;
          padding-bottom: 56.5%;
          overflow: hidden;
          z-index: 1;

          .thumbnail-pic {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: center top/cover no-repeat;
            transition: transform 400ms cubic-bezier(0.4, 0, 0.25, 1), opacity 1s cubic-bezier(0.4, 0, 0.25, 1), -webkit-transform 400ms cubic-bezier(0.4, 0, 0.25, 1);
            transform: scale(1.001);

          }

          i {
            position: absolute;
            top: 50%;
            left: 50%;
            color: #fff;
            font-size: 30px;
            transform: translate(-50%, -50%);
            text-shadow: 0 0 15px #000;
            opacity: 0;
            transition: opacity .3s;
            z-index: 2;
          }
        }

        p {
          font: 400 11px/1.2em $opensans;
          margin: 0 auto;
          @include tablet {
            font-size: 12px;
          }
          @include desktop {
            font-size: 13px;
          }

          .grey {
            display: inline-block;
            color: #8C9698;
            text-transform: uppercase;
            font-weight: 600;
            margin: 0 auto 7px;
            line-height: 1.2em;
          }

          .blue {
            display: inline-block;
            color: $drumeoBlue;
            text-transform: uppercase;
            font-weight: 600;
            margin: 0 auto 7px;
            line-height: 1.2em;
          }

          strong {
            font-size: 15px;
            line-height: 1.3em;
            @include desktop {
              font-size: 19px;
            }

            br {
              content: ' ';

              &:after {
                content: ' ';
              }
            }
          }

          .excerpt {
            margin: 5px auto 0;
            display: inline-block;
            line-height: 22px;
            max-height: 66px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;

            //max-height: 60px;
            //line-height: 1.7em;
            //overflow: hidden;
            //position: relative;
            //&:after {
            //  content: ' ';
            //  position: absolute;
            //  top: 45px;
            //  left: 0;
            //  right: 0;
            //  height: 15px;
            //  background: linear-gradient(0deg, #FFF, transparent);
            //}
          }
        }
      }
    }

    .post-list {
      padding: 0 10px;
      @include tablet {
        padding: 0 12px;
      }

      .post-tile {
        color: #000;
        border-bottom: 1px solid #CCD3D3;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        align-content: stretch;
        padding: 10px 0;
        @include tablet {
          padding: 15px 0;
        }

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          .image-fill:after {
            opacity: 1;
          }

          i {
            color: #000;
          }
        }

        .image-fill {
          min-width: 100px;
          background: center top/cover no-repeat;
          border-radius: 5px;
          padding-bottom: 65px;
          position: relative;
          @include tablet {
            min-width: 110px;
          }

          &:after {
            content: '\f04b';
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            font: 900 22px/65px "Font Awesome 5 Pro";
            color: #FFF;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.3);
            text-align: center;
            opacity: 0;
            transition: opacity .3s ease-in;
          }
        }

        p {
          font: 700 13px/1em $opensans;
          margin: 0;
          padding: 0 15px;
          flex-grow: 1;
          @include tablet {
            font-size: 15px;
          }
          @include desktop {
            font-size: 20px;
          }

          .grey {
            display: inline-block;
            color: #8C9698;
            text-transform: uppercase;
            font-weight: 600;
            margin: 0 auto 3px;
            line-height: 1.1em;
            font-size: 11px;
            @include tablet {
              font-size: 12px;
            }
            @include desktop {
              font-size: 13px;
            }
          }

          em {
            line-height: 1em;
            font-weight: 500;
            text-transform: uppercase;
            color: #8C9698;
            font-size: 11px;
            @include tablet {
              font-size: 13px;
            }
          }
        }

        img {
          width: 190px;
          margin: 0 15px 0 auto;
          @include desktop {
            width: 230px;
            margin: 0 25px 0 auto;
          }
        }

        i {
          font-size: 20px;
          color: #CCD3D3;
          @include tablet {
            font-size: 30px;
          }
          @include desktop {
            font-size: 35px;
          }
        }

        &.DrumeoPodcast .image-fill:before,
        &.DrumeoGab .image-fill:before {
          content: '';
          position: absolute;
          bottom: 0;
          right: 0;
          width: 35px;
          height: 25px;
          background: rgba(0, 0, 0, 0.5) url(https://dpwjbsxqtam5n.cloudfront.net/podcast/logo.png) center center/25px no-repeat;
          border-radius: 5px 0 5px 0;
          z-index: 2;
        }

        &.DrumeoGab .image-fill:before {
          background-image: url(https://dpwjbsxqtam5n.cloudfront.net/beat/drumeo-gab-logo.svg);
        }
      }

      h1 {
        font: 500 18px/1.2em $opensans;
        margin: 0;
        width: auto;
        padding-bottom: 5px;
        @include tablet {
          font-size: 24px;
        }
        @include desktop {
          font-size: 32px;
        }
      }
    }

    .tile-grid {
      .post-tile {
        margin: 0 auto 20px;
        @include tablet {
          margin: 0 auto 24px;
          padding: 0 12px;
        }

        a {
          color: inherit;
        }

        .tile-contents {
          text-align: center;
          padding-bottom: 100%;
          color: #fff;
          border-radius: 5px;
          position: relative;
          overflow: hidden;
          z-index: 1;

          &:hover {
            .image-fill {
              transform: scale(1.11);

            }

            i {
              opacity: 1;
            }
          }

          .image-fill {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #1a1c1d center top/cover;
            transition: transform 4s ease;
            transform: scale(1.01);
          }

          i {
            position: absolute;
            top: 75%;
            left: 50%;
            color: #fff;
            font-size: 30px;
            transform: translate(-50%, -50%);
            text-shadow: 0 0 15px #000;
            opacity: 0;
            transition: opacity .3s;
            z-index: 2;
          }

          p {
            position: absolute;
            top: 50%;
            width: 100%;
            transform: translate(0, -50%);
            z-index: 2;
            text-shadow: 0 0 15px #000;
            font: 400 17px/1em $opensans;
            margin: 0 auto;

            strong {
              text-transform: uppercase;
              font-family: $robotocondensed;
              font-size: 54px;
              line-height: 1em;
            }
          }
        }
      }
    }

    .load-more-button {
      color: #BBB;
      text-align: center;
      border-radius: 100px;
      font: 700 16px/1em $robotocondensed;
      padding: 15px;
      width: 100%;
      text-transform: uppercase;
      transition: all .3s ease-in;
      height: auto;
      max-height: 100px;
      visibility: visible;
      opacity: 1;

      &.fade-out {
        height: 0;
        max-height: 0;
        visibility: hidden;
        opacity: 0;
      }
    }

    iframe {
      width: 100%;
      margin: 25px auto 20px;
      @include tablet {
        margin: 35px auto 25px;
      }
      @include desktop {
        margin: 45px auto 35px;
      }

      &.email-form-include {
        margin: 0 auto;
        height: 110px;
        @include tablet {
          height: 55px;
        }
      }

      &.email-form-include-full {
        margin: 0 auto;
        border-radius: 10px;
        height: 220px;
        @include tablet {
          height: 140px;
        }
        @include desktop {
          height: 160px;
        }
      }
    }

    .author-box {
      border-top: 1px solid #BBB;
      width: 100%;
      margin: 40px auto 0;
      padding: 30px 15px;
      text-align: center;
      @include tablet {
        text-align: left;
        display: flex;
        align-items: center;
        margin: 40px auto 0;
        padding: 30px 0;
      }
      @include desktop {
        margin: 60px auto 0;
        padding: 50px 0;

      }

      img {
        border-radius: 1000px;
        margin: 0 auto 15px;
        width: 100px;
        @include tablet {
          margin: 0 auto;
          width: 135px;
        }
      }

      p {
        font: 400 13px/1.6em $opensans;
        margin: 0 auto !important;
        @include tablet {
          padding-left: 30px;
          font-size: 15px;
        }
        @include desktop {
          font-size: 16px;
        }
      }
    }
  }

  &.homepage .white-box .post-grid.full-width .post-tile {
    @include tablet {
      margin-bottom: 24px;
    }

    a {
      @include tablet {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        align-content: stretch;
      }
    }

    .image-fill {
      @include tablet {
        padding-bottom: 27%;
        margin-bottom: 0;
        flex: 1 1 auto;
        max-width: 48%;
      }
    }

    p {
      @include tablet {
        max-width: 52%;
        padding-left: 24px;
        flex: 1 1 auto;
      }

      strong {
        @include desktop {
          font-size: 25px;
        }
      }
    }

  }

  &.post-page .white-box {
    clear: both;
    max-width: 840px;
    margin: 0 auto;

    h1, h2, h3, h4, h5, h6, p, hr, ul, ol, video {
      width: 100%;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    p {
      margin: 0 auto 15px;

      &.active {
        transition: all .3s, font 0s, margin 0s;

        &.sticky-vid {
          position: fixed;
          z-index: 98;
          display: block;
          margin: 0 auto;
          width: 100%;
          max-width: 100%;
          left: 0;
          right: 0;
          text-align: right !important;
          top: 40px;
          font-size: 0;
          height: 170px;
          @include tablet {
            top: 56px;
            height: 141px;
          }
          @include desktop {
            height: 210px;
          }

          iframe {
            height: 100% !important;
            max-width: 100%;
            margin: 0 auto;
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.35);
            @include tablet {
              max-width: 250px;
            }
            @include desktop {
              max-width: 373px;
            }
          }
        }
      }
    }

    h1, h2, h3, h4 {
      margin-top: 25px;

      &:nth-child(3) {
        margin-top: 0;
      }
    }

    h2, h3, h4, h5 {
      margin-bottom: 20px;
    }

    ul, ol {
      padding-left: 1.25rem;
      margin-bottom: 30px;
    }

    &.Videos p:nth-child(2) iframe,
    &.Videos p:nth-child(3) iframe,
    &.Other p:nth-of-type(2) iframe {
      margin-top: 0;
    }

    blockquote {
      font: 700 27px/1.1em $robotocondensed;
      text-transform: uppercase;
      color: $drumeoBlue;
      width: 100%;
      margin: 0 auto 15px;
      border-left: 5px solid $drumeoBlue;
      padding: 10px 0 10px 15px;
      @include tablet {
        font-size: 31px;
        padding: 15px 0 15px 25px;
      }
      @include desktop {
        font-size: 35px;
      }

      p {
        color: inherit;
        font: inherit;
        margin: 0 auto;
      }
    }

    .wp-block-embed {
      width: 100%;
      margin: 20px auto;
      text-align: center;
      @include tablet {
        margin: 30px auto;
      }

      &.is-type-wp-embed {
        text-align: left;
      }

      iframe {
        margin: 0 auto !important;
      }
    }

    mark {
      background: #fff480;
      padding: 0 5px;
    }

    .blue-text-block {
      background: transparentize($drumeoBlue, 0.85);
      display: inline-block;
      width: 100%;
      border-radius: 5px;
      padding: 13px 20px;
      @include tablet {
        padding: 17px 25px;
      }
    }

    a {
      word-break: break-word;
      color: $drumeoBlue;

      &.join {
        color: #fff;
      }
    }

    .video-buffer {
      margin: 0 auto;

      &.active {

        .close-sticky {
          right: 7px;
          opacity: 1;
        }

        div {
          margin: 0 auto 30px;
          @include tablet {
            margin: 0 auto 40px;
          }
          @include desktop {
            margin: 0 auto 50px;
          }
        }
      }

      div {
        padding-bottom: 56.5%;
        margin-bottom: -56.5%;
      }

      .close-sticky {
        position: fixed;
        right: -15px;
        z-index: 99;
        background: #fff;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.38);
        opacity: 0;
        transition: all .3s;
        cursor: pointer;
        transform: scale(1.01);
        top: 217px;
        @include tablet {
          top: 247px;
        }
        @include desktop {
          top: 282px;
        }

        &:hover {
          transform: scale(1.11);
        }
      }
    }

    img {
      margin: 20px auto;
      @include tablet {
        margin: 25px auto;
      }
      @include desktop {
        margin: 35px auto;
      }

      &.alignleft {
        @include tablet {
          margin: 10px 20px 20px 0;
        }
      }

      &.alignright {
        @include tablet {
          margin: 10px 0 20px 20px;
        }
      }
    }

    .wp-caption-text, figcaption {
      font-style: italic;
      color: #999;
      font-size: 12px;
    }

    .wp-caption, .wp-block-image {
      width: 100% !important;
      position: relative;
      margin: 30px auto;
      @include tablet {
        margin: 40px auto;
      }
      @include desktop {
        margin: 50px auto;
      }

      img {
        margin: 0 auto;
      }

      &.full-width {
        @media (min-width: 840px) {
          max-width: none !important;
          margin: 30px auto;
        }

        img {
          @media (min-width: 840px) {
            object-fit: cover;
            position: relative;
            height: auto;
            max-height: 700px !important;
            width: 99vw;
            max-width: 99vw;
            left: 50%;
            margin-left: -50vw;
          }
        }

        .wp-caption-text, figcaption {
          margin: 0 auto;
          @media (min-width: 1110px) {
            margin: 15px auto 0;
          }
        }
      }

      &.alignleft {
        @include tablet {
          width: auto !important;
          margin: 10px 20px 20px 0;
        }
      }

      &.alignright {
        @include tablet {
          width: auto !important;
          margin: 10px 0 20px 20px;
        }
      }
    }

    .wp-block-columns.are-vertically-aligned-center .wp-block-image,
    .wp-block-columns.are-vertically-aligned-center iframe,
    .wp-block-columns.are-vertically-aligned-center .wp-block-embed {
      margin: 0 auto;
    }

    video {
      display: block;
      margin: 0 auto 15px;
    }

    table {
      max-width: 100%;
      margin: 20px auto;
      @include tablet {
        margin: 25px auto;
      }

      h1, h2, h3, h4, h5, h6 {
        margin: 0 auto;
      }
    }

    .mejs-container {
      background: #000;
      border-radius: 5px;
      overflow: hidden;
      max-width: 100%;
      margin: 0 auto 15px;

      * {
        font-family: $robotocondensed;
        font-weight: 700;
      }

      .mejs-controls .mejs-time-rail .mejs-time-current {
        background: $drumeoBlue;
      }

      .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total,
      .mejs-controls .mejs-time-rail .mejs-time-total {
        border-radius: 5px;
        overflow: hidden;
      }
    }

    .metaslider {
      max-width: 100%;
      margin: 30px auto 50px;
      @include tablet {
        margin: 40px auto 60px;
      }
      @include desktop {
        margin: 50px auto 70px;
      }

      .flexslider {
        margin: 0;

        &:hover .flex-direction-nav {
          .flex-next {
            right: 10px;
          }

          .flex-prev {
            left: 10px;
          }
        }

        .flex-viewport {
          border-radius: 5px;
        }

        .caption-wrap {
          opacity: 1;
          text-align: center;

          .caption {
            padding: 15px 10px;
            font: 700 14px/1em $robotocondensed;
            text-transform: uppercase;
            @include tablet {
              font-size: 16px;
              padding: 20px 15px;
            }
          }
        }

        .flex-control-nav {
          margin: 0 auto;
          padding: 0;
        }

        .flex-direction-nav {
          .flex-next {
            right: 5px;
          }

          .flex-prev {
            left: 5px;
          }

          li a {
            border-radius: 50%;
            color: #fff;
            background: #000;
            text-indent: unset;
            font: 700 22px/1em $opensans;
            text-align: center;
            padding: 0;
            border: 2px solid #fff;
            opacity: 0.5;
          }
        }
      }
    }

    .typeform-widget {
      width: 100%;
      max-width: 100%;
      height: 670px;
      margin: 0 auto;

      iframe {
        margin: 0 auto;
      }
    }

    .share-buttons {
      font-size: 13px;
      @include tablet {
        font-size: 14px;
      }
      @include desktop {
        font-size: 15px;
      }

      #copyClipboard.active {
        color: #10d05f;
      }
    }

    &.latest-post-wrap {
      max-width: 100%;
      padding: 0;

      hr {
        max-width: 100%;
      }

      .more-posts {
        background: #eff0f0;
        padding: 20px 10px;
        @include tablet {
          padding: 20px;
        }
      }
    }

    .subscription-list {

      h1 {
        font: 900 22px/1em $opensans;
        text-transform: uppercase;
        margin: 0 auto 15px;
        @include tablet {
          font-size: 22px;
          margin: 0 auto 20px;
        }

        @include desktop {
          font-size: 28px;
          margin: 0 auto 25px;
        }
      }

      .subscribe-options {
        width: 100%;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        float: none;

        .subscribe-app {
          background: #000c17;
          display: flex;
          flex-direction: row;
          border-radius: 5px;
          transition: background-color .1s linear;
          text-align: left;
          margin: 0 auto 15px;

          &:hover {
            @include tablet {
              background: lighten(#000c17, 5%);
            }
          }

          .image,
          .text {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          .image {
            width: 28%;
            min-width: 28%;
            padding: 0 7px 0 10px;
            @include tablet {
              padding: 10px;
            }

            img {
              border-radius: 50%;
              flex-shrink: 0;
              margin: 0 auto;
            }
          }

          .text {
            flex-grow: 1;
            min-width: 130px;
            padding: 10px 10px 10px 0;

            p {
              font: 700 14px $opensans;
              text-transform: uppercase;
              margin: 0;
              color: #FFF;
              @include tablet {
                font-size: 14px;
              }

              @include desktop {
                font-size: 16px;
              }
            }
          }
        }
      }
    }

    .product-grid {
      width: 100%;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;

      .columns {
        margin: 0 auto 30px;

        .product-grid-item {
          text-align: center;
          border: 1px solid #dedede;
          border-radius: 5px;
          padding: 20px;

          img {
            margin: 0 auto;
            width: 100%;
            max-width: 160px;
            border-radius: 5px;
          }

          h6 {
            font-weight: 700;
            margin: 15px auto;
            min-height: 44px;
          }

          a {
            text-decoration: underline;
          }
        }
      }
    }

    .the-greats {
      width: 100%;
      max-width: 830px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;

      .columns {
        margin: 0 auto 20px;

        a {
          position: relative;
          display: block;

          &:hover {
            opacity: 0.8;

            i {
              opacity: 1;
            }
          }
        }

        br {
          display: none;
        }

        img {
          border-radius: 50%;
          margin: 0 auto;
        }

        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #fff;
          text-shadow: 0 0 15px #000;
          font-size: 30px;
          opacity: 0;
          transition: opacity .3s;
        }

        p {
          font: 400 16px/1em $opensans;
          margin: 7px auto 0;
          color: #000;
        }
      }
    }

    .the-records {
      width: 100%;
      max-width: 830px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;

      .columns {
        margin: 0 auto 20px;

        img {
          border-radius: 5px;
          margin: 0 auto;
        }

        p {
          font: 400 14px/1.2em $opensans;
          margin: 7px auto 0;
        }
      }
    }

    .alignnone,
    a img.alignnone {
      @include tablet {
        margin: 5px auto;
      }
    }

    .aligncenter,
    div.aligncenter {
      display: block;
      margin: 5px auto;
    }

    a img.aligncenter,
    .wp-block-image .aligncenter {
      display: block;
      margin-left: auto;
      margin-right: auto
    }

    .alignright,
    a img.alignright,
    .wp-block-image .alignright {
      display: block;
      float: none;
      @include tablet {
        float: right;
        margin-left: 20px;
      }
    }

    .alignleft,
    a img.alignleft,
    .wp-block-image .alignleft {
      display: block;
      float: none;
      @include tablet {
        float: left;
        margin-right: 20px;
      }
    }

    .wp-block-image figure.aligncenter {
      display: table;
    }
  }

  &.search-results .white-box {
    .search-field {
      margin: 0 auto 25px;
      padding: 0 10px;
      @include tablet {
        margin: 0 auto 30px;
        padding: 0 12px;
      }
      font-size: 0;

      input {
        box-shadow: none;
        border-radius: 100px 0 0 100px;
        border: 1px solid #BCC1C2;
        border-right: 0;
        margin: 0;
        display: inline-block;
        font: 400 15px/38px $opensans;
        padding: 0 20px;
        vertical-align: middle;
        width: calc(100% - 70px);
        height: 40px;
        @include tablet {
          width: calc(100% - 100px);
          line-height: 48px;
          height: 50px;
          font-size: 18px;
        }
      }

      button {
        display: inline-block;
        border-radius: 0 100px 100px 0;
        border: 1px solid $drumeoBlue;
        background: $drumeoBlue;
        color: #fff;
        font-size: 15px;
        line-height: 38px;
        vertical-align: middle;
        cursor: pointer;
        outline: none;
        width: 70px;
        @include tablet {
          width: 100px;
          line-height: 48px;
          font-size: 18px;
        }

        &:hover {
          background: lighten($drumeoBlue, 10%);
          border-color: lighten($drumeoBlue, 10%);
        }

      }

    }

    .search-results-description p {
      text-transform: uppercase;
      color: #879193;
      font: 400 14px/1em $opensans;
      margin: 0 auto 10px;
      padding: 0 10px;
      @include tablet {
        margin: 0 auto 15px;
        padding: 0 12px;
      }
    }

    .pagination {
      margin: 0;

      .screen-reader-text {
        display: none;
      }

      .nav-links {
        float: left;
        width: 100%;
        text-align: center;
        padding: 7px;
        height: 55px;
        @include tablet {
          border-radius: 0 0 5px 5px;
        }

        .page-numbers {
          display: inline-block;
          background: #EEE;
          border-radius: 5px;
          margin: 3px 2px;
          color: #8C9698;
          width: 35px;
          font: 700 16px/35px $opensans;
          padding: 0;
          @include tablet {
            width: 40px;
            line-height: 40px;
            font-size: 18px;
            margin: 0 1px;
          }

          &.current {
            color: #FFF;
            background: #8C9698;
          }

          &:hover {
            @include tablet {
              color: #FFF;
              background: lighten(#8C9698, 15%);
            }
          }

          i {
            font-size: 19px;
            @include tablet {
              font-size: 22px;
            }
          }
        }
      }
    }
  }
}

.grid-header {
  background-color: #ebeced;
  padding: 20px 10px;
  @include tablet {
    padding: 35px 10px;
  }
  @include desktop {
    padding: 55px 10px;
  }

  p.title {
    border-bottom: 2px solid;

    i {
      border-radius: 200px;
      padding: 3px 3px;
      font-size: 10px;
      color: #ebeced;
    }
  }

  a.post-tile:nth-child(n+6) {
    display: none;
  }

  .post-tile {
    margin: 0 auto 20px;
    @include tablet {
      margin: 0 auto 25px;
      padding: 0 12px;
    }
    @include desktop {
      margin: 0 auto 30px;
    }

    &.DrumeoPodcast .image-fill:before,
    &.DrumeoGab .image-fill:before {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 65px;
      height: 50px;
      background: rgba(0, 0, 0, 0.5) url(https://dpwjbsxqtam5n.cloudfront.net/podcast/logo.png) center center/49px no-repeat;
      border-radius: 5px 0 5px 0;
      z-index: 2;
    }

    &.DrumeoGab .image-fill:before {
      background-image: url(https://dpwjbsxqtam5n.cloudfront.net/beat/drumeo-gab-logo.svg);
    }

    a {
      color: #000;

      &:hover .image-fill {
        .thumbnail-pic {
          transform: scale(1.03);
        }

        i {
          opacity: 1;
        }
      }
    }

    .image-fill {
      position: relative;
      margin: 0 auto 17px;
      border-radius: 5px;
      padding-bottom: 56.5%;
      overflow: hidden;
      z-index: 1;

      .thumbnail-pic {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: center top/cover no-repeat;
        transition: transform 400ms cubic-bezier(0.4, 0, 0.25, 1), opacity 1s cubic-bezier(0.4, 0, 0.25, 1), -webkit-transform 400ms cubic-bezier(0.4, 0, 0.25, 1);
        transform: scale(1.001);

      }

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        color: #fff;
        font-size: 30px;
        transform: translate(-50%, -50%);
        text-shadow: 0 0 15px #000;
        opacity: 0;
        transition: opacity .3s;
        z-index: 2;
      }
    }

    p {
      font: 400 11px/1.2em $opensans;
      margin: 0 auto;
      @include tablet {
        font-size: 12px;
      }
      @include desktop {
        font-size: 13px;
      }

      .grey {
        display: inline-block;
        color: #8C9698;
        text-transform: uppercase;
        font-weight: 600;
        margin: 0 auto 7px;
        line-height: 1.2em;
      }

      .blue {
        display: inline-block;
        color: $drumeoBlue;
        text-transform: uppercase;
        font-weight: 600;
        margin: 0 auto 7px;
        line-height: 1.2em;
      }

      strong {
        font-size: 15px;
        line-height: 1.3em;
        @include desktop {
          font-size: 19px;
        }

        br {
          content: ' ';

          &:after {
            content: ' ';
          }
        }
      }

      .excerpt {
        margin: 5px auto 0;
        display: inline-block;
        line-height: 22px;
        max-height: 66px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        //max-height: 60px;
        //line-height: 1.7em;
        //overflow: hidden;
        //position: relative;
        //&:after {
        //  content: ' ';
        //  position: absolute;
        //  top: 45px;
        //  left: 0;
        //  right: 0;
        //  height: 15px;
        //  background: linear-gradient(0deg, #FFF, transparent);
        //}
      }
    }
  }

  .list-view {
    p.title {
      margin: 15px auto 5px;
      @include tablet {
        margin: 0 auto 5px;
      }
    }

    .post-tile {
      color: #000;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      align-content: stretch;
      padding: 10px 0;
      margin: 0 auto;

      &:hover {
        .image-fill:after {
          opacity: 1;
        }

        i {
          color: #000;
        }
      }

      &:nth-child(5) {
        display: none;
        @include desktop {
          display: flex;
        }
      }

      &:nth-child(6) {
        display: none;
      }

      .image-fill {
        min-width: 100px;
        background: center top/cover no-repeat;
        border-radius: 5px;
        padding-bottom: 60px;
        margin: 0 auto;
        position: relative;
        @include desktop {
          min-width: 170px;
          padding-bottom: 95px;
        }

        &:after {
          content: '\f04b';
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          display: inline-block;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          font: 900 22px/95px "Font Awesome 5 Pro";
          color: #FFF;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: rgba(0, 0, 0, 0.3);
          text-align: center;
          opacity: 0;
          transition: opacity .3s ease-in;
        }
      }

      p {
        font: 700 13px/1em $opensans;
        margin: 0;
        padding: 0 0 0 15px;
        flex-grow: 1;
        @include tablet {
          font-size: 15px;
        }
        @include desktop {
          font-size: 20px;
        }

        .grey {
          display: inline-block;
          color: #8C9698;
          text-transform: uppercase;
          font-weight: 600;
          margin: 0 auto 7px;
          line-height: 1.2em;
          font-size: 13px;
        }
      }

      i {
        display: none;
      }
    }
  }
}

.reveal-overlay {
  background-color: rgba(0, 0, 0, 0.8);

  &:after {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    content: "\f00d";
    color: #fff;
    z-index: 1;
    opacity: 0.8;
    position: absolute;
    margin: 0;
    line-height: 1em;
    text-align: center;
    display: inline-block;
    outline: none;
    top: 0;
    right: 0;
    font-size: 35px;
    width: 35px;
    @include tablet {
      top: 7px;
      right: 7px;
      font-size: 50px;
      width: 50px;
    }
  }

  .reveal {
    height: inherit;
    min-height: 0;
    outline: none;
    padding: 0;
    border: none;
    border-radius: 5px;
    -webkit-overflow-scrolling: touch;

    .flex-video {
      margin: 0 auto;
    }
  }
}

.yarpp {
  background: #eff0f0;
  margin: 0 !important;

  .section-header {
    padding: 30px 12px 0;
    text-transform: uppercase;
    font: 600 13px/1em $opensans;
    color: #000;

    hr {
      margin: 20px auto 25px;
      max-width: 100%;
    }
  }

  .post-tile {
    margin: 0 auto 20px;
    @include tablet {
      margin: 0 auto 25px;
      padding: 0 12px;
    }
    @include desktop {
      margin: 0 auto 30px;
    }

    a {
      color: #000;

      &:hover .image-fill {
        .thumbnail-pic {
          transform: scale(1.03);
        }

        i {
          opacity: 1;
        }
      }
    }

    .image-fill {
      position: relative;
      margin: 0 auto 17px;
      border-radius: 5px;
      padding-bottom: 56.5%;
      overflow: hidden;
      z-index: 1;

      .thumbnail-pic {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: center top/cover no-repeat;
        transition: transform 400ms cubic-bezier(0.4, 0, 0.25, 1), opacity 1s cubic-bezier(0.4, 0, 0.25, 1), -webkit-transform 400ms cubic-bezier(0.4, 0, 0.25, 1);
        transform: scale(1.001);

      }

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        color: #fff;
        font-size: 30px;
        transform: translate(-50%, -50%);
        text-shadow: 0 0 15px #000;
        opacity: 0;
        transition: opacity .3s;
        z-index: 2;
      }
    }

    p {
      font: 400 11px/1.2em $opensans;
      margin: 0 auto;
      @include tablet {
        font-size: 12px;
      }
      @include desktop {
        font-size: 13px;
      }

      .grey {
        display: inline-block;
        color: #8C9698;
        text-transform: uppercase;
        font-weight: 600;
        margin: 0 auto 7px;
        line-height: 1.2em;
      }

      .blue {
        display: inline-block;
        color: $drumeoBlue;
        text-transform: uppercase;
        font-weight: 600;
        margin: 0 auto 7px;
        line-height: 1.2em;
      }

      strong {
        font-size: 15px;
        line-height: 1.3em;
        @include desktop {
          font-size: 19px;
        }

        br {
          content: ' ';

          &:after {
            content: ' ';
          }
        }
      }

      .excerpt {
        margin: 5px auto 0;
        display: inline-block;
        line-height: 22px;
        max-height: 66px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
}

.box-snippet {
  position: relative;
  margin: 40px auto;
  width: 96%;

  .content {
    display: flex;
    padding: 10px;
    position: relative;
    color: white;
    background: $drumeoBlue;
    border-radius: 2px;

    .icon {
      margin-right: 10px;
      background: white;
      color: $drumeoBlue;
      font-size: 24px;
      padding: 10px;
      border-radius: 100%;
      min-width: 44px;
      text-align: center;
    }

    .text-container {
      padding: 4px 0;
      position: relative;
      z-index: 10;

      a {
        color: #fff;
        text-decoration: underline;
      }
    }
  }

  .shadow {
    border: 3px solid $drumeoBlue;
    position: absolute;
    width: 100%;
    height: 100%;
    left: -10px;
    top: 10px;
    border-radius: 2px;
  }
}